/*
 *
 * LanguageProvider actions
 *
 */

export const SET_RUN = 'joyride/SET_RUN';
export const setRun = run => ({ type: SET_RUN, run });

export const SET_STEP_INDEX = 'joyride/SET_STEP_INDEX';
export const setStepIndex = stepIndex => ({ type: SET_STEP_INDEX, stepIndex });
