/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router/immutable';
import App, { Fallback } from 'containers/App'; // Import root app
import LanguageProvider from 'containers/LanguageProvider'; // Import Language Provider
import history from 'utils/history';
import configureStore from './configureStore';
import { translationMessages } from './i18n'; // Import i18n messages
import favicon from './images/favicon.ico'; // Load the favicon and the .htaccess file
// import FontFaceObserver from 'fontfaceobserver';
// import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Roboto', {});

// Initialize Sentry.io as early as possible
if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		environment: process.env.SENTRY_ENVIRONMENT,
		integrations: [
			new BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) })
			// new Sentry.Replay()
		],
		release: process.env.VERSION,
		// This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
		tracesSampleRate: 1.0
	});
}

// When Open Sans is loaded, add a font-family using Open Sans to the body
// openSansObserver.load().then(() => {
// 	document.body.classList.add('fontLoaded');
// });

// Initialize Google Analytics
if (process.env.GOOGLE_ANALYTICS_ID) {
	ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);
	ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

if (process.env.GOOGLE_TAG_MANAGER_ID) {
	TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER_ID });
}

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const mountNode = document.getElementById('app');

const render = messages => {
	ReactDOM.render(
		<Provider store={store}>
			<Sentry.ErrorBoundary fallback={Fallback}>
				<LanguageProvider messages={messages}>
					<ConnectedRouter history={history}>
						<App />
					</ConnectedRouter>
				</LanguageProvider>
			</Sentry.ErrorBoundary>
		</Provider>,
		mountNode
	);
};

if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./i18n', 'containers/App'], () => {
		ReactDOM.unmountComponentAtNode(mountNode);
		render(translationMessages);
	});
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
	new Promise(resolve => resolve(import('intl')))
		.then(() =>
			Promise.all([
				import('intl/locale-data/jsonp/en.js')
				// import('intl/locale-data/jsonp/de.js')
			])) // eslint-disable-line prettier/prettier
		.then(() => render(translationMessages))
		.catch(err => {
			throw err;
		});
} else {
	render(translationMessages);
}

// Userback
const userback = window.Userback || {};
userback.access_token = process.env.USERBACK_TOKEN;
window.Userback = userback;
(function(d) {
	const s = d.createElement('script');
	s.async = true;
	s.src = 'https://static.userback.io/widget/v1.js';
	(d.head || d.body).appendChild(s);
})(document);

// Debug mode
// const body = document.getElementsByTagName('body')[0];
// body.classList.toggle('not-debug');
// document.addEventListener('keydown', e => {
// 	// console.log(e.code, e);
// 	if (e.code === 'KeyA' && e.altKey) body.classList.toggle('not-debug');
// });
