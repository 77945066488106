import Radio from '@material-ui/core/Radio';
import { createTheme } from '@material-ui/core/styles';
import React from 'react';

const base = createTheme({
	breakpoints: {
		values: {
			xs: 0, // default: 0
			sm: 576, // default: 600
			md: 992, // default: 960
			lg: 1200, // default: 1280
			xl: 1400 // default: 1920
		}
	},
	palette: {
		primary: {
			main: '#5B63B6'
			// dark: '#4d5ad8'
		},
		secondary: {
			main: '#e3e6ff',
			contrastText: '#5b63b6',
			dark: '#d3d7fd'
		},
		text: {
			primary: '#000000'
			// primary: '#56646E'
			// primary: '#1a294d'
		}
	}
});

const { pxToRem } = base.typography;
const { up } = base.breakpoints;

export const config = {
	...base,
	appBar: { height: '70px' },
	fontFamily: 'Roboto, sans-serif',
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			color: 'primary',
			// disableElevation: true,
			variant: 'outlined'
		},
		MuiCheckbox: {
			color: 'primary'
		},
		MuiFormControlLabel: {
			control: <Radio />
		},
		MuiMenu: {
			disableScrollLock: true,
			anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
			transformOrigin: { vertical: 'top', horizontal: 'right' }
		},
		MuiPopover: {
			disableScrollLock: true,
			anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
			transformOrigin: { vertical: 'top', horizontal: 'right' }
		},
		MuiRadio: {
			color: 'primary'
		},
		MuiSwitch: {
			color: 'primary',
			size: 'small'
		},
		MuiTabs: {
			indicatorColor: 'primary',
			textColor: 'primary',
			variant: 'scrollable',
			scrollButtons: 'auto'
		}
	},
	spacing: factor => (window.innerWidth >= 1920 ? 8 * factor : 6 * factor),
	typography: {
		fontSize: 11, // default: 14
		button: {
			textTransform: 'none',
			fontWeight: 'normal',
			fontSize: pxToRem(11), // default: 14
			[up('xl')]: {
				fontSize: pxToRem(13)
			}
		},
		body1: {
			fontSize: pxToRem(11), // default: 16
			[up('xl')]: {
				fontSize: pxToRem(13)
			}
		},
		body2: {
			fontSize: pxToRem(11), // default 14
			[up('xl')]: {
				fontSize: pxToRem(13)
			}
		}
	},
	// shape: { borderRadius: 2 },
	overrides: {
		MuiButton: {
			sizeSmall: {
				fontSize: 10,
				[up('xl')]: {
					fontSize: pxToRem(12)
				}
			},
			sizeLarge: {
				fontSize: 13,
				[up('xl')]: {
					fontSize: pxToRem(14)
				}
			},
			root: {
				whiteSpace: 'nowrap'
			}
		},
		MuiChip: {
			root: {
				fontSize: pxToRem(10), // Default 14
				[up('xl')]: {
					fontSize: pxToRem(12)
				}
			}
		},
		MuiFormControlLabel: {
			root: {
				marginRight: 4
			}
		},
		MuiInput: {
			root: {
				fontSize: pxToRem(11), // Default 14
				[up('xl')]: {
					fontSize: pxToRem(13)
				}
			},
			underline: {
				'&&&&:before': {
					borderWidth: '1px',
					borderColor: '#e0e0e0'
				}
			}
		},
		MuiInputLabel: {
			root: {
				fontSize: pxToRem(11), // Default 14
				[up('xl')]: {
					fontSize: pxToRem(13)
				}
			},
			formControl: {
				color: 'rgba(0, 0, 0, 0.3)'
			}
		},
		MuiSlider: {
			root: {
				height: 8
			},
			thumb: {
				height: 18,
				width: 18,
				marginTop: -5,
				marginLeft: -9
			},
			rail: {
				height: 8,
				borderRadius: 4
			},
			track: {
				height: 8,
				borderRadius: 4
			}
		},
		MuiTableCell: {
			root: {
				borderBottom: '1px solid #f2f2f2'
			},
			sizeSmall: {
				padding: '12px 24px',
				fontSize: pxToRem(10), // Default 14
				[up('xl')]: {
					fontSize: pxToRem(12)
				}
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: pxToRem(10), // Default 14
				fontWeight: 400,
				[up('xl')]: {
					fontSize: pxToRem(12)
				}
			}
		},
		MuiToggleButton: {
			root: {
				borderColor: '#5b63b680',
				color: base.palette.primary.main,
				padding: '5px 15px',
				'&$selected': {
					backgroundColor: base.palette.primary.main,
					color: base.palette.primary.contrastText,
					'&:hover': {
						backgroundColor: base.palette.primary.dark
					}
				}
			}
		}
	}
};

const theme = createTheme(config);
// console.log('theme:', theme);
// theme = responsiveFontSizes(theme, { factor: 3 , variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']});
export default theme;
