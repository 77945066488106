/*
 *
 * hocHeader
 *
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { setRun } from 'containers/Joyride/actions';
import { getUser, logout } from 'modules/auth/actions';
import { selectFeatures, selectUser } from 'modules/auth/selectors';

const mapStateToProps = createStructuredSelector({
	user: selectUser(),
	features: selectFeatures()
});

const mapDispatchToProps = dispatch => bindActionCreators({ getUser, logout, showJoyride: setRun }, dispatch);

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { user } = stateProps;
	return Object.assign({}, user, ownProps, dispatchProps);
};
const hoc = connect(
		mapStateToProps,
		mapDispatchToProps,
		mergeProps
);

export default hoc;

