/**
 * Create the store with dynamic reducers
 */

import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { fromJS } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import { googleAnalytics } from './utils/reactGAMiddlewares';

export default function configureStore(initialState = {}, history) {
	let composeEnhancers = compose;
	const reduxSagaMonitorOptions = {};

	// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
	/* istanbul ignore next */
	if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
		/* eslint-disable no-underscore-dangle */
		if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
			composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
		}

		// NOTE: Uncomment the code below to restore support for Redux Saga
		// Dev Tools once it supports redux-saga version 1.x.x
		// if (window.__SAGA_MONITOR_EXTENSION__)
		//   reduxSagaMonitorOptions = {
		//     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
		//   };
		/* eslint-enable */
	}

	const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

	const sentryReduxEnhancer = Sentry.createReduxEnhancer({
		// Optionally pass options listed below
	});

	// Create the store with three middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	// 3. googleAnalytics: Sends page changes to Google Analytics
	const middlewares = [sagaMiddleware, routerMiddleware(history), googleAnalytics];

	const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer];

	const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

	// Extensions
	store.runSaga = sagaMiddleware.run;
	store.injectedReducers = {}; // Reducer registry
	store.injectedSagas = {}; // Saga registry

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (module.hot) {
		module.hot.accept('./reducers', () => store.replaceReducer(createReducer(store.injectedReducers)));
	}

	return store;
}
