import React from 'react';
import Joyride from 'react-joyride';
import { useInjectReducer } from 'utils/injectReducer';
import hoc from './hoc';
import reducer from './reducer';

const key = 'joyride';

function MJoyride({ run, setRun, stepIndex, setStepIndex }) {
	useInjectReducer({ key, reducer });
	// useEffect(() => setTimeout(() => setRun(true), 3000), []);

	const callback = ({ action, index, status, type }) => {
		console.log(action, index, status, type);
		if (['close'].indexOf(action) !== -1) {
			setRun(false);
		} else if (['finished', 'skipped'].indexOf(status) !== -1) {
			// Need to set our running state to false, so we can restart if we click start again.
			setRun(false);
		} else if (['step:after', 'target-not-found'].indexOf(type) !== -1) {
			const nextStepIndex = index + (action === 'prev' ? -1 : 1);
			setStepIndex(nextStepIndex);
		}
	};

	return (
		<Joyride
			continuous
			showProgress
			showSkipButton
			scrollOffset={200}
			run={run}
			// debug
			stepIndex={stepIndex}
			callback={callback}
			steps={[
				// {
				// 	target: '#gl-menu',
				// 	content: 'This is my awesome feature!',
				// 	disableBeacon: true,
				// 	styles: { options: { width: 300 } },
				// 	floaterProps: { disableAnimation: true },
				// 	placement: 'top',
				// 	placementBeacon: 'top',
				// 	title: 'Our Mission'
				// },
				{
					title: 'Welcome',
					content: 'Take a few seconds to learn how to use GenLots',
					target: 'body',
					placement: 'center',
					disableBeacon: true
				},
				{
					target: '.ReactTable ',
					content: 'The dashboard allows you to manage orders'
				},
				{
					target: '#orders-filters',
					content: 'You can filter materials by MRP controller, order date, etc.'
				},
				{
					target: '.ReactTable .rt-tbody .rt-tr:first-child',
					content: 'Click on a material to see the details, like opening date or estimated coverage.',
					disableBeacon: true,
					disableOverlayClose: true,
					hideCloseButton: true,
					hideFooter: true,
					spotlightClicks: true
				},
				{
					target: '#gl-menu-orders',
					content: 'Hereders',
					spotlightPadding: 0,
					placement: 'right',
					disableScrolling: true
				},
				{
					target: '#gl-menu',
					content: 'This another awesome feature!2',
					spotlightPadding: 0,
					placement: 'right',
					disableScrolling: true
				}
			]}
			styles={{
				options: {
					primaryColor: '#5B63B6',
					textColor: '#35424E',
					zIndex: 10000
					// arrowColor: '#fff',
					// backgroundColor: '#fff',
					// beaconSize: 36,
					// overlayColor: 'rgba(0, 0, 0, 0.5)',
					// spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
					// width: undefined
				},
				tooltip: {
					fontSize: 14,
					padding: 30
				},
				tooltipTitle: {
					fontSize: 16
				},
				buttonNext: {
					fontSize: 14,
					padding: '8px 15px',
					fontWeight: '400',
					outline: 'none'
				},
				buttonBack: {
					fontSize: 14,
					padding: '8px 15px',
					fontWeight: '400'
				},
				buttonClose: {
					padding: 20
				}
			}}
			// scrollToFirstStep
			// disableScrolling
			// disableOverlayClose
			// disableOverlay
			// hideBackButton
			// hideCloseButton
			// scrollOffset
		/>
	);
}

export default hoc(MJoyride);
