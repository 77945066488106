export const CALL_API = 'CALL_API';

export const createRequestTypes = base => {
	const res = {};
	['REQUEST', 'SUCCESS', 'FAILURE'].forEach(type => {
		res[type] = `${base}_${type}`;
	});
	return res;
};

export const action = (type, payload = {}) => ({
	type,
	...payload
});

export const responseBuilder = entity => ({
	request: () => action(entity.REQUEST),
	success: response => action(entity.SUCCESS, { response }),
	failure: error => action(entity.FAILURE, { error })
});
