import { put } from 'redux-saga/effects';
import { getUser } from '../auth/actions';

function* fetchData(actualPath) {
	if (['/login', '/reset', '/recover'].indexOf(actualPath) === -1) {
		yield put(getUser());
	}
}

export default function* onRouteChangeSaga() {
	yield fetchData(window.location.pathname);
}
