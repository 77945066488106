/**
 * i18n.js
 *
 * This will set up the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const { createIntl, createIntlCache } = require('react-intl');


const enTranslationMessages = require('./translations/en.json');

const cache = createIntlCache();

const intl = createIntl({
	locale: 'en',
	messages: enTranslationMessages,
}, cache);

const DEFAULT_LOCALE = 'en';

// prettier-ignore
const appLocales = [
	'en'
];

const formatTranslationMessages = (locale, messages) => {
	const defaultFormattedMessages = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {};
	const flattenFormattedMessages = (formattedMessages, key) => {
		const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
		return Object.assign(formattedMessages, { [key]: formattedMessage });
	};
	return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
	en: formatTranslationMessages('en', enTranslationMessages)
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE
exports.intl = intl;
