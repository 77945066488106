import axios from 'axios';
import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

import config from 'config.js';
import { CALL_API, responseBuilder } from './index';

let cancelTokenSource = axios.CancelToken.source();

export const cancelAllRequests = () => {
	cancelTokenSource.cancel();
	cancelTokenSource = axios.CancelToken.source();
};

// Csrf token
let csrfToken;

const callApi = (url, method = 'GET', data = null) => {
	const headers = { Accept: 'application/json' };

	if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(method)) {
		// if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && !this.crossDomain) {
		headers['X-CSRF-Token'] = csrfToken;
	}
	if (data instanceof FormData) {
		// Don't set the 'Content-Type' header when sending a FormData object
		// axios will automatically set it to 'multipart/form-data'
	}else if (data) {
		headers['Content-Type'] = 'application/json';
	}

	return axios({
		method,
		url: `${config.apiUrl}${url}`,
		data,
		headers,
		withCredentials: true,
		cancelToken: cancelTokenSource.token
	})
		.then(response => {
			// eslint-disable-next-line no-shadow
			const { data } = response;
			// console.groupCollapsed('[CALL API]', method, `/${url} >`, callType.REQUEST);
			// groupLog('REQUEST', request, data);
			// groupLog('RESPONSE', callType.SUCCESS, response);
			// groupLog('ERROR', callType.FAILURE, response.err);
			// groupLog('json', data);
			// console.groupEnd();

			if ([200, 201, 202, 203, 204].indexOf(response.status) === -1) {
				return Promise.reject(data);
			}

			// FIXME: Catch csrf from login
			if (data && data.response && data.response.csrf_token) csrfToken = data.response.csrf_token;

			return { response: data };
		})
		.catch(error => {
			if (error.response) {
				// Case 1: Received response from server
				if (error.response.data.response) {
					const r = error.response.data.response;
					if (r.error) {
						return { error: r.error };
					}
					if (r.errors) {
						return { error: Object.values(r.errors)[0] };
					}
				}
				if (error.response.data.error) {
					return { error: error.response.data.error };
				}
				// Case 2: No server response
				return { error: error.response.statusText };
			}
			return { error: error.err || error };
		});
};

function* apiSaga(props) {
	// credentials
	if (typeof window === 'undefined') {
		return;
	}

	const { endpoint, method, body, callType, params, query } = props;

	let endPointRes = endpoint;
	if (params) {
		// TODO check if get else throw Error
		endPointRes = [
			endPointRes,
			Object.keys(params)
				.map(key => `/${params[key]}`)
				.join('') // join('&'),
		].join(''); // join('&'),
	}
	if (query) {
		endPointRes = [
			endPointRes,
			Object.keys(query)
				.map(key => {
					let result;
					if (Array.isArray(query[key])) {
						result = query[key].map(value => `${key}=${value}`).join('&');
					} else {
						result = `${key}=${query[key]}`;
					}
					return result;
				})
				.join('&') // join('&'),
		].join('?'); // join('&'),
	}

	const apiFnc = () => callApi(endPointRes, method, body, callType);
	yield fetchEntity(callType, apiFnc);
}

function* fetchEntity(entity, apiFn) {
	const resp = responseBuilder(entity);
	yield put(resp.request());
	const { response, error } = yield call(apiFn);

	if (!error) {
		yield put(resp.success(response));
	} else {
		const message = `${error}`;
		if (message !== 'You are not authenticated. Please supply the correct credentials.') {
			toast.error(`${message}`);
		}
		yield put(resp.failure(error));
	}
}

export default function* apiSagaCatch() {
	yield takeEvery(CALL_API, apiSaga);
}
