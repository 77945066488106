import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectJoyride = state => state.get('joyride') || initialState;

export const makeSelectRun = () =>
	createSelector(
		selectJoyride,
		state => state.run
	);
export const makeStepIndex = () =>
	createSelector(
		selectJoyride,
		state => state.stepIndex
	);
