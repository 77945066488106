import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';

// Initial routing state
const initialState = fromJS({ previousLocation: null, location: null });

/**
 * Merge route into the global application state
 */
export default function routing(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return state.merge({ previousLocation: state.get('location'), location: action.payload });
		default:
			return state;
	}
}
