import { createSelector } from 'reselect';

const selectLibraryDomain = () => state => state.get('auth');

export const selectIsLogged = () =>
	createSelector(
		selectLibraryDomain(),
		state => state.get('isLogged')
	); // DOT NOT TOUCH NAMING selectIsLog -> selectIsLogged make config with something..

export const selectUser = () =>
	createSelector(
		selectLibraryDomain(),
		state => (state.get('user') ? state.get('user').toJS() : state.get('user'))
	);

export const selectMaterials = () =>
	createSelector(
		selectLibraryDomain(),
		state => (state.getIn(['user', 'materials']) ? state.getIn(['user', 'materials']).toJS() : [])
	);

export const selectCategories = () =>
	createSelector(
		selectLibraryDomain(),
		state => (state.getIn(['user', 'categories']) ? state.getIn(['user', 'categories']).toJS() : [])
	);

export const selectFeatures = () =>
	createSelector(
		selectLibraryDomain(),
		state => (state.getIn(['user', 'features']) ? state.getIn(['user', 'features']).toJS() : {})
	);

export const selectCompany = () =>
	createSelector(
		selectLibraryDomain(),
		state => (state.getIn(['user', 'companyConfig']) ? state.getIn(['user', 'companyConfig']).toJS() : {})
	);
