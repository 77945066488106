export default {
	apiUrl: process.env.API_URL,
	colors: {
		gray: '#7f7f7f',
		rhino: '#333367',
		blueViolet: '#5B63B6',
		osloGray: '#93989C'
	},
	locale: ['en'], // ['en', 'fr', 'de'],
	localStoragePrefix: 'GenLots',
	version: process.env.VERSION,
	app: {
		scale: 0.7,
		title: 'GenLots',
		description: '',
		head: {
			titleTemplate: '%s - GenLots',
			defaultTitle: 'GenLots'
			// link: [
			// 	{
			// 		rel: 'icon',
			// 		href: '/static/favicon.ico',
			// 		type: 'image/x-icon'
			// 	}, {
			// 		rel: 'apple-touch-icon',
			// 		sizes: '180x180',
			// 		href: '/static/apple-touch-icon.png'
			// 	}, {
			// 		rel: 'icon',
			// 		sizes: '32x32',
			// 		href: '/static/favicon-32x32.png',
			// 		type: 'image/png'
			// 	}, {
			// 		rel: 'icon',
			// 		sizes: '16x16',
			// 		href: '/static/favicon-16x16.png',
			// 		type: 'image/png'
			// 	}, {
			// 		rel: 'manifest',
			// 		sizes: '180x180',
			// 		href: '/static/manifest.json'
			// 	}, {
			// 		rel: 'mask-icon',
			// 		href: '/static/safari-pinned-tab.svg',
			// 		color: '#5bbad5'
			// 	}
			// ],
			//
			// meta: [
			// 	{ charset: 'utf-8' }, {
			// 		name: 'description',
			// 		content: ''
			// 	}, {
			// 		name: 'theme-color',
			// 		content: '#CCCFD3'
			// 	}, {
			// 		name: 'viewport',
			// 		content: `width=device-width, initial-scale=${scaleApp}, maximum-scale=${scaleApp}, user-scalable=0`
			// 	}
			// ]
		}
	}
};
