/**
 * Redux middleware for Google Analytics to track page changes.
 *
 * Shamelessly copied from: https://github.com/react-ga/react-ga/wiki/React-Router-v4-Redux-Middleware
 */

import ReactGA from 'react-ga4';

const options = {};

let currentPage = '';

const trackPage = page => {
	ReactGA.set({ page, ...options });
	ReactGA.send({ hitType: 'pageview', page });
};

export const googleAnalytics = () => next => action => {
	if (process.env.GOOGLE_ANALYTICS_ID) {
		if (action.type === '@@router/LOCATION_CHANGE') {
			const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

			if (currentPage !== nextPage) {
				currentPage = nextPage;
				trackPage(nextPage);
			}
		}
	}

	return next(action);
};
