/*
 *
 * LanguageProvider reducer
 *
 */

import { produce }  from 'immer';

import { SET_RUN, SET_STEP_INDEX } from './actions';

export const initialState = {
	// run: !localStorage.getItem('joyride'),
	run: false,
	stepIndex: 0
};
// localStorage.setItem('joyride', true);

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case SET_RUN:
				draft.run = action.run;
				draft.stepIndex = 0;
				break;
			case SET_STEP_INDEX:
				draft.stepIndex = action.stepIndex;
				break;
		}
	});

export default reducer;
