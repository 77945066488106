/*
 *
 * Calculate reducer
 *
 */

import { fromJS } from 'immutable';

import { GET_MATERIAL } from '../../../modules/material/actions';
import { CALCULATE_NUMBER, CALCULATE_SSO, CLEAR_RESULTS } from './actions';

const initialState = fromJS({
	results: null,
	resultsSSO: [],
	loading: false,
	loadingMaterial: false,
	loadingCalculation: false
});

const calculateReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_RESULTS:
			return state.set('results', fromJS(null)).set('resultsSSO', fromJS([]));

		case CALCULATE_NUMBER.REQUEST:
			return state.set('loadingCalculation', true).set('resultsSSO', fromJS([]));

		case CALCULATE_NUMBER.SUCCESS:
			return state.set('loadingCalculation', false).set('results', fromJS(action.response));

		case CALCULATE_NUMBER.FAILURE:
			return state.set('loadingCalculation', false);

		case GET_MATERIAL.REQUEST:
			return state.set('loadingMaterial', true).set('resultsSSO', fromJS([]));
		case GET_MATERIAL.SUCCESS:
		case GET_MATERIAL.FAILURE:
			return state.set('loadingMaterial', false);

		case CALCULATE_SSO.REQUEST:
			return state.set('loading', true);

		case CALCULATE_SSO.SUCCESS: // eslint-disable-line no-case-declarations
			// console.log('warnings', action.response.warnings); // eslint-disable-line no-console
			let results = state.get('resultsSSO');
			results = results ? results.toJS() : [];
			results.push(action.response);
			return state.set('resultsSSO', fromJS(results)).set('loading', false);

		case CALCULATE_SSO.FAILURE:
			return state.set('loading', false);

		default:
			return state;
	}
};

export default calculateReducer;
