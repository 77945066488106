/*
 *
 * Calculate actions
 *
 */
import { CALL_API, createRequestTypes } from 'modules/api';

export const CALCULATE_SSO = createRequestTypes('CALCULATE_SSO');
export const calculateSSO = body => ({
	type: CALL_API,
	endpoint: '/calculateSSO',
	method: 'POST',
	callType: CALCULATE_SSO,
	body
});

export const CALCULATE_NUMBER = createRequestTypes('CALCULATE_NUMBER');
export const calculateNumber = id => ({
	type: CALL_API,
	endpoint: `/material/${id}/cacheCalculate`,
	method: 'GET',
	callType: CALCULATE_NUMBER
});

export const CLEAR_RESULTS = 'App/calculate/CLEAR_RESULTS';
export const clearResults = () => ({ type: CLEAR_RESULTS });
