import { fromJS } from 'immutable';

import { GET_OPPORTUNITIES } from './actions';

const initialState = fromJS({
	data: [],
	kpi: {}
});

function opportunitiesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_OPPORTUNITIES.SUCCESS:
			return state.set('data', fromJS(action.response.ops)).set('kpi', fromJS(action.response.kpiCards));
		case GET_OPPORTUNITIES.FAILURE:
		default:
			return state;
	}
}

export default opportunitiesReducer;
