import URLSearchParams from '@ungap/url-search-params';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function getSearchParameter(name) {
	const query = new URLSearchParams(window.location.search);
	return query.get(name);
}

export function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

// Flask decode one so if you send one encode / become %2F that becomes a / so we need double encoding
// https://github.com/pallets/flask/issues/900
export const multipleEncode = name => encodeURIComponent(encodeURIComponent(name));
