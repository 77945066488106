/*
 *
 * hocHeader
 *
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { setRun, setStepIndex } from './actions';
import { makeSelectRun, makeStepIndex } from './selectors';

const mapStateToProps = createStructuredSelector({
	run: makeSelectRun(),
	stepIndex: makeStepIndex()
});

const mapDispatchToProps = dispatch => bindActionCreators({ setRun, setStepIndex }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
);
