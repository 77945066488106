import React, { forwardRef } from 'react';
import { Link as OLink, useLocation } from 'react-router-dom';

export const Link = forwardRef((props, ref) => <OLink {...props} />);

export const LinkWithQuery = forwardRef(({ to, ...props }, ref) => {
	const { search } = useLocation();
	return <OLink to={to + search} {...props} />;
});

export const A = forwardRef((props, ref) => <a {...props} />);
