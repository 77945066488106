import { produce}  from 'immer';
import { SET_FILTERS, SET_SORT } from './actions';

// The initial state
export const initialState = {
	filters: [],
	sort: []
};

/* eslint-disable default-case, no-param-reassign */
const opportunitiesTableReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case SET_FILTERS:
				draft.filters = action.filters;
				break;
			case SET_SORT:
				draft.sort = action.sort;
				break;
		}
	});

export default opportunitiesTableReducer;
