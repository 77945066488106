import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import IsoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(IsoWeek);
dayjs.extend(advancedFormat);

export const sortSAPDate = (a, b) => {
	if (!a && !b) return 0;
	if (!a) return 1;
	if (!b) return -1;

	const a0 = a.split('/');
	const b0 = b.split('/');
	const a1 = a0[1] + a0[0];
	const b1 = b0[1] + b0[0];
	return a1.localeCompare(b1);
};

export const frequencyToUnit = frequency => `${frequencyToUnitSingular(frequency)}s`;

export const frequencyToUnitSingular = frequency => {
	const units = {
		DAILY: 'Day',
		WEEKLY: 'Week',
		MONTHLY: 'Month'
	};
	return units[frequency];
};

// export const materialToFrequency = material => `${materialToHorizon(material)} ${frequencyToUnit(material.frequency)}`;

export const daysToHorizon = (days, frequency) => {
	if (frequency === 'DAILY') {
		return days;
	}
	if (frequency === 'MONTHLY') {
		return Math.ceil(days / 30);
	}
	return Math.ceil(days / 7);
};

export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DD hh:mm';
export const formatDate = (date, format = dateFormat, def = '-') => {
	if (!date) return def;
	if (typeof date === 'string') {
		date = dayjs(date);
	}
	return date.format(format);
};
export const formatDateTime = (date, format = dateTimeFormat) => formatDate(date, format);
export const formatUTCDate = (date, format = dateFormat, def = '-') =>
	date
		? dayjs
			.utc(date)
			.local()
			.format(format)
		: def;
export const formatUTCDateTime = (date, format = dateTimeFormat, def) => formatUTCDate(date, format, def);

export const formatDateFrequency = (date, frequency) => {
	if (frequency === 'MONTHLY') {
		return formatDate(date, '[M] MM/YYYY');
	}
	if (frequency === 'WEEKLY') {
		return formatDate(date, '[W] WW/GGGG');
	}
	if (frequency === 'DAILY') {
		return formatDate(date, '[D] YYYY-MM-DD');
	}
	return formatDate(date);
};

export const labelToDate = (label, frequency) => {
	if (frequency === 'MONTHLY') {
		return dayjs(label, '[M] MM/YYYY');
	}
	if (frequency === 'WEEKLY') {
		// eslint-disable-next-line no-unused-vars
		const [full, week, year] = label.match(/W (\d\d)\/(\d\d\d\d)/);
		return dayjs(getDateOfISOWeek(week, year));
		// return dayjs(label, '[W] WW/GGGG'); // DOES NOT WORK IN DAY.js
	}
	return dayjs(label);
};

export const formatLabel = (label, frequency) => formatDate(labelToDate(label, frequency));

function getDateOfISOWeek(w, y) {
	const simple = new Date(y, 0, 1 + (w - 1) * 7);
	const dow = simple.getDay();
	const ISOweekStart = simple;
	if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
	else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
	return ISOweekStart;
}

export const dateToLabel = formatDateFrequency;

export const dateToWeek = d => formatDateFrequency(d, 'WEEKLY');

export const dateToWeekShort = d => d.format('[W] WW');
