import { fromJS } from 'immutable';
import ReactGA from 'react-ga4';
import { GET_USER, LOGIN_USER, LOGOUT_USER } from './actions';

const initialState = fromJS({
	isLogged: false,
	user: null
});

function authReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_USER.SUCCESS:
			return state.set('isLogged', true);
		case GET_USER.SUCCESS: {
			if (action.response.msg) {
				return state.set('user', null).set('isLogged', false);
			}
			const init = {
				features: {},
				categories: [],
				labels: {},
				messages: [],
				materials: [],
				companies: [],
				companyConfig: {}
			};

			const prev = state.get('user');
			if (prev && prev.toJS().user) init.user = prev.toJS().user;
			if (prev && prev.toJS().companies) init.companies = prev.toJS().companies;

			action.response.user = action.response.username || action.response.user || init.user; // eslint-disable-line no-param-reassign
			const user = Object.assign(init, action.response);
			if (process.env.GOOGLE_ANALYTICS_ID && user.user) {
				ReactGA.set({ userId: user.user.split('@')[0] });
				// ReactGA.set({ userId: user.userId });
			}
			return state.set('isLogged', true).set('user', fromJS(user));
		}
		case LOGIN_USER.FAILURE:
		case GET_USER.FAILURE:
		case LOGOUT_USER.SUCCESS:
			return state.set('user', null).set('isLogged', false);
		default:
			return state;
	}
}

export default authReducer;
