export const selectedClass = (styles, selected, name) => {
	if (!selected) {
		return `${styles[name]} ${styles[`${name}_Unselected`]}`;
	}
	return styles[name];
};

export const mergeClasses = (...argg) => {
	const classes = [];

	for (let i = 0; i < argg.length; i++) {
		const arg = argg[i];
		if (!arg) continue;
		const argType = typeof arg;

		if (argType === 'string' || argType === 'number') {
			classes.push(arg);
		} else if (Array.isArray(arg) && arg.length) {
			const inner = mergeClasses(...arg);
			if (inner) {
				classes.push(inner);
			}
		} else if (argType === 'object') {
			for (const key in arg) {
				if (arg.hasOwnProperty(key) && arg[key]) {
					classes.push(key);
				}
			}
		}
	}
	return classes.join(' ');
};

export const hasClass = (ele, cls) => ele.getAttribute('class').indexOf(cls) > -1;

export const addClass = (ele, cls) => {
	if (ele.classList) {
		ele.classList.add(cls);
	} else if (!hasClass(ele, cls)) {
		ele.setAttribute('class', `${ele.getAttribute('class')} ${cls}`);
	}
};

export const removeClass = (ele, cls) => {
	if (ele.classList) {
		ele.classList.remove(cls);
	} else if (hasClass(ele, cls)) {
		ele.setAttribute('class', ele.getAttribute('class').replace(cls, ' '));
	}
};
