import PropTypes from 'prop-types';
import React from 'react';

import config from 'config.js';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';

export const Logo = ({ className, inverted = false, color = config.colors.rhino, ...etc }) => {
	const normalSvg = (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 120" {...etc}>
			<text transform="matrix(1 0 0 1 211.1409 908.42)" className={styles.Text}>
				{' '}
				GenLots
			</text>
			<path
				style={{ fill: color }}
				d="M160.7 81.2c-4.9 2.8-11.1 4.3-18.4 4.3 -8.1 0-14.6-2.3-19.3-6.8 -4.7-4.5-7-10.7-7-18.6 0-8 2.6-14.4 7.7-19.4 5.1-5 12-7.5 20.5-7.5 5.4 0 10.1 0.7 14.2 2.2v10.7c-3.9-2.3-8.7-3.4-14.3-3.4 -4.7 0-8.6 1.5-11.6 4.6 -3 3.1-4.5 7.2-4.5 12.3 0 5.2 1.3 9.2 4 12.1 2.7 2.8 6.3 4.3 10.8 4.3 2.7 0 4.9-0.4 6.5-1.2v-9.9h-10.1v-9.1h21.5V81.2z"
			/>
			<path
				style={{ fill: color }}
				d="M200.1 69.7h-23.6c0.4 5.2 3.7 7.9 9.9 7.9 4 0 7.5-0.9 10.5-2.8v8c-3.3 1.8-7.7 2.7-13 2.7 -5.8 0-10.4-1.6-13.6-4.9 -3.2-3.2-4.8-7.7-4.8-13.5 0-6 1.7-10.8 5.2-14.3 3.5-3.5 7.8-5.3 12.8-5.3 5.3 0 9.3 1.6 12.2 4.7 2.9 3.1 4.3 7.4 4.3 12.7V69.7zM189.8 62.9c0-5.2-2.1-7.8-6.3-7.8 -1.8 0-3.3 0.7-4.6 2.2 -1.3 1.5-2.1 3.3-2.4 5.5H189.8z"
			/>
			<path
				style={{ fill: color }}
				d="M239.9 84.6h-11.1V64.5c0-5.6-2-8.4-6-8.4 -1.9 0-3.5 0.7-4.8 2.2 -1.2 1.5-1.9 3.4-1.9 5.6v20.6H205V48.5h11.2v5.7h0.1c2.7-4.4 6.5-6.6 11.6-6.6 8 0 12 5 12 14.9V84.6z"
			/>
			<path style={{ fill: color }} d="M278.1 84.6H248V34h11.4v41.4h18.7V84.6z" />
			<path
				style={{ fill: color }}
				d="M299.3 85.5c-6 0-10.8-1.7-14.2-5.1 -3.4-3.4-5.2-8-5.2-13.7 0-6 1.8-10.7 5.4-14 3.6-3.4 8.4-5.1 14.5-5.1 6 0 10.7 1.7 14.1 5.1 3.4 3.4 5.1 7.8 5.1 13.4 0 6-1.8 10.7-5.3 14.2C310.2 83.8 305.4 85.5 299.3 85.5zM299.5 56.1c-2.6 0-4.7 0.9-6.1 2.7 -1.5 1.8-2.2 4.4-2.2 7.7 0 6.9 2.8 10.4 8.4 10.4 5.3 0 8-3.6 8-10.7C307.6 59.5 304.9 56.1 299.5 56.1z"
			/>
			<path
				style={{ fill: color }}
				d="M346.3 84.2c-1.6 0.9-4.1 1.3-7.4 1.3 -7.9 0-11.8-4.1-11.8-12.2V56.7h-5.9v-8.2h5.9v-7.8l11.1-3.2v11h8.1v8.2h-8.1v14.6c0 3.8 1.5 5.6 4.5 5.6 1.2 0 2.4-0.3 3.6-1V84.2z"
			/>
			<path
				style={{ fill: color }}
				d="M348.4 83.7v-9c1.8 1.1 3.7 1.9 5.5 2.5 1.8 0.5 3.5 0.8 5.2 0.8 2 0 3.5-0.3 4.7-0.8 1.1-0.5 1.7-1.4 1.7-2.5 0-0.7-0.3-1.3-0.8-1.8 -0.5-0.5-1.2-0.9-2-1.2 -0.8-0.4-1.7-0.7-2.7-1 -1-0.3-1.9-0.6-2.8-1 -1.4-0.5-2.7-1.1-3.8-1.7 -1.1-0.6-2-1.3-2.8-2.2S349.3 64 349 63c-0.4-1.1-0.6-2.4-0.6-3.8 0-2 0.4-3.8 1.3-5.2 0.9-1.5 2.1-2.7 3.5-3.6 1.5-0.9 3.2-1.6 5-2 1.9-0.4 3.9-0.7 5.9-0.7 1.6 0 3.2 0.1 4.9 0.4 1.6 0.2 3.3 0.6 4.9 1.1v8.6c-1.4-0.8-2.9-1.4-4.5-1.9 -1.6-0.4-3.2-0.6-4.7-0.6 -0.7 0-1.4 0.1-2.1 0.2 -0.6 0.1-1.2 0.3-1.7 0.6 -0.5 0.2-0.9 0.6-1.2 1 -0.3 0.4-0.4 0.8-0.4 1.3 0 0.7 0.2 1.2 0.6 1.7 0.4 0.5 1 0.9 1.7 1.2 0.7 0.3 1.4 0.6 2.3 0.9 0.8 0.3 1.7 0.5 2.5 0.8 1.5 0.5 2.8 1.1 4 1.7 1.2 0.6 2.2 1.3 3.1 2.2 0.9 0.8 1.5 1.8 2 2.9 0.5 1.1 0.7 2.4 0.7 4 0 2.1-0.5 4-1.4 5.5 -0.9 1.5-2.2 2.8-3.7 3.7 -1.5 1-3.3 1.6-5.3 2.1 -2 0.4-4.1 0.7-6.3 0.7C355.4 85.5 351.8 84.9 348.4 83.7z"
			/>
			<path
				style={{ fill: color }}
				d="M104.5 32.1v55.8l-48.3 27.9L7.8 87.9V32.1L56.1 4.2 73 13.9l-5.9 3.4 -11-6.3L16.5 33.9l39.7 22.9 39.7-22.9 -9.7-5.6c0 0 0 0 0 0l-7.9-4.6 -22 12.7L50.3 33l27.9-16.1 7 4.1v0L104.5 32.1zM98.6 84.5V39L59.1 61.9v45.5L98.6 84.5zM53.2 107.3V61.9L13.7 39v45.5L53.2 107.3z"
			/>
			<path style={{ fill: color }} d="M94.7 45.8v35.9c-11.5-6.4-25.9-14.6-31.3-17.9L94.7 45.8z" />
			<path style={{ fill: color }} d="M94.1 82.6l-31.1 18V64.8C68.5 68.2 81.7 75.7 94.1 82.6z" />
			<path
				style={{ fill: color }}
				d="M88 33.9L56.1 52.2 24.3 33.9l9.9-5.7 14.1 8.3c2.6 1.5 5.2 3 7.8 4.5 7.3-4.2 14.7-8.5 22-12.7 2.8 1.6 5.7 3.3 8.5 4.9L88 33.9z"
			/>
			<path style={{ fill: color }} d="M49.3 64.8v35.8l-31.1-18C30.5 75.7 43.8 68.2 49.3 64.8z" />
			<path style={{ fill: color }} d="M17.6 45.8l31.3 18.1C43.5 67.2 29 75.4 17.6 81.8V45.8z" />
		</svg>
	);
	const invertedSvg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="378"
			height="122"
			viewBox="0 0 378 122"
			{...etc}>
			<defs>
				<path id="a" d="M0.7729 0.6595L28.515 0.6595 28.515 38.5675 0.7729 38.5675z" />
			</defs>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g fill="#FFF">
					<path
						d="M52.504 52.653l31.83-18.37-1.23-.71c-2.85-1.64-5.7-3.29-8.55-4.93-7.32 4.23-14.65 8.46-21.98 12.69l-7.83-4.53-14.14-8.25-9.92 5.73 31.82 18.37zm48.34 35.66v-55.81l-19.25-11.12v.01l-7.04-4.06-27.85 16.08 5.87 3.39 21.98-12.69 7.93 4.58h.01l9.68 5.59-39.67 22.9-39.66-22.9 39.66-22.9 10.98 6.33 5.88-3.39-16.86-9.73-48.34 27.91v55.81l48.34 27.91 48.34-27.91zm3.92-58.08v60.35c-17.42 10.05-34.84 20.11-52.26 30.17-17.42-10.06-34.84-20.12-52.25-30.17v-60.35c17.41-10.05 34.83-20.11 52.25-30.17 8.24 4.75 16.47 9.51 24.7 14.26l-.01.01 3.57 2.06.01-.01c7.34 4.24 14.69 8.48 22.03 12.72l1.96 1.13z"
						transform="translate(0 .19)"
					/>
					<path
						d="M59.364 100.953l31.11-17.97c-12.35-6.88-25.63-14.39-31.11-17.82v35.79zm31.68-18.77v-35.95l-31.28 18.07c5.38 3.31 19.84 11.49 31.28 17.88zm3.92-42.73v45.47l-39.52 22.81v-45.47l39.52-22.81zM45.254 64.303l-31.29-18.06v35.94c11.45-6.38 25.91-14.56 31.29-17.88zm.4 36.65v-35.79c-5.48 3.44-18.76 10.94-31.11 17.83l31.11 17.96zm3.91-38.68v45.47l-39.52-22.82v-45.47l39.52 22.82z"
						transform="translate(0 .19)"
					/>
				</g>
				<path
					fill="#FFF"
					d="M159.82 81.487c-4.94 2.847-11.082 4.271-18.424 4.271-8.141 0-14.56-2.253-19.253-6.759-4.695-4.507-7.043-10.713-7.043-18.619 0-7.953 2.565-14.436 7.695-19.449 5.13-5.011 11.954-7.517 20.472-7.517 5.365 0 10.095.741 14.189 2.223v10.695c-3.906-2.259-8.683-3.388-14.33-3.388-4.73 0-8.583 1.535-11.56 4.605-2.976 3.071-4.464 7.171-4.464 12.301 0 5.2 1.335 9.224 4.006 12.071 2.67 2.848 6.276 4.271 10.818 4.271 2.729 0 4.894-.388 6.494-1.164v-9.884h-10.13v-9.107h21.53v25.45zM188.898 63.133c0-5.177-2.095-7.767-6.283-7.767-1.788 0-3.336.743-4.642 2.225-1.305 1.482-2.1 3.33-2.382 5.542h13.307zm10.34 6.846h-23.576c.376 5.248 3.682 7.872 9.918 7.872 3.976 0 7.47-.942 10.483-2.823v8.046c-3.341 1.79-7.684 2.683-13.024 2.683-5.837 0-10.366-1.617-13.59-4.853-3.224-3.235-4.835-7.748-4.835-13.536 0-6 1.74-10.754 5.223-14.26 3.483-3.505 7.766-5.259 12.849-5.259 5.27 0 9.347 1.565 12.23 4.694 2.88 3.13 4.323 7.377 4.323 12.743v4.693zM238.976 84.876h-11.12V64.792c0-5.6-2-8.4-6-8.4-1.928 0-3.517.74-4.763 2.223-1.248 1.482-1.871 3.365-1.871 5.647v20.614h-11.155V48.732h11.154v5.718h.142c2.657-4.4 6.53-6.6 11.613-6.6 8 0 12 4.964 12 14.895v22.13z"
				/>
				<path fill="#FFF" d="M277.2295 84.8756L247.0875 84.8756 247.0875 34.2606 258.4875 34.2606 258.4875 75.6286 277.2295 75.6286z" />
				<path
					fill="#FFF"
					d="M298.647 56.391c-2.635 0-4.683.906-6.141 2.718-1.46 1.811-2.188 4.376-2.188 7.695 0 6.941 2.8 10.412 8.4 10.412 5.342 0 8.012-3.565 8.012-10.696 0-6.753-2.694-10.129-8.083-10.129m-.282 29.366c-6.024 0-10.76-1.687-14.207-5.065-3.447-3.377-5.171-7.96-5.171-13.748 0-5.976 1.789-10.653 5.366-14.03 3.576-3.376 8.412-5.065 14.506-5.065 6 0 10.707 1.69 14.119 5.064 3.41 3.378 5.118 7.844 5.118 13.396 0 6.001-1.76 10.742-5.277 14.225-3.518 3.482-8.336 5.223-14.454 5.223M345.372 84.451c-1.647.87-4.13 1.306-7.448 1.306-7.859 0-11.788-4.082-11.788-12.248V56.956h-5.86v-8.225h5.86v-7.8l11.118-3.176v10.976h8.118v8.225h-8.118V71.57c0 3.765 1.494 5.647 4.482 5.647 1.177 0 2.388-.342 3.636-1.024v8.26z"
				/>
				<g transform="translate(349 47.19)">
					<mask id="b" fill="#fff">
						<use xlinkHref="#a" />
					</mask>
					<path
						fill="#FFF"
						d="M.773 36.731v-9.036c1.835 1.107 3.664 1.931 5.488 2.473 1.824.541 3.547.81 5.17.81 1.978 0 3.536-.269 4.677-.81 1.142-.541 1.712-1.366 1.712-2.473 0-.706-.258-1.294-.776-1.764s-1.182-.882-1.995-1.236a22.656 22.656 0 00-2.664-.951c-.966-.284-1.895-.6-2.788-.954-1.436-.54-2.7-1.122-3.795-1.747-1.094-.623-2.012-1.346-2.753-2.17a8.53 8.53 0 01-1.694-2.86c-.39-1.082-.582-2.365-.582-3.848 0-2.022.44-3.763 1.323-5.222a10.802 10.802 0 013.53-3.583c1.47-.93 3.153-1.611 5.047-2.047A26.33 26.33 0 0116.586.66c1.599 0 3.223.122 4.87.37a36.34 36.34 0 014.87 1.077v8.611a17.604 17.604 0 00-4.534-1.852 19.133 19.133 0 00-4.748-.617c-.73 0-1.418.064-2.065.192a6.882 6.882 0 00-1.712.566c-.494.248-.882.565-1.164.954a2.188 2.188 0 00-.424 1.322c0 .659.212 1.224.636 1.694.423.471.976.877 1.658 1.218a16.28 16.28 0 002.277.918c.835.271 1.665.546 2.488.83a32.337 32.337 0 014.024 1.694c1.2.613 2.229 1.328 3.088 2.152a8.44 8.44 0 011.977 2.894c.459 1.108.689 2.425.689 3.954 0 2.142-.465 3.97-1.395 5.489a11.179 11.179 0 01-3.706 3.707c-1.541.953-3.318 1.647-5.33 2.083a29.574 29.574 0 01-6.265.652c-3.977 0-7.66-.612-11.047-1.837"
						mask="url(#b)"
					/>
				</g>
			</g>
		</svg>
	);

	return <div className={mergeClasses(styles.Logo, className)}>{!inverted ? normalSvg : invertedSvg}</div>;
};

export const Icon = ({ className, ...etc }) => (
	<div className={className}>
		<svg width="110px" height="127px" viewBox="0 0 110 127" version="1.1" xmlns="http://www.w3.org/2000/svg" {...etc}>
			<g id="VD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="00-Styleguide-04" transform="translate(-923.000000, -629.000000)" fill="#FFFFFF">
					<g id="Group-7-Copy-2" transform="translate(923.000000, 629.000000)">
						<path
							d="M54.9947373,54.9778772 L88.4967946,35.799652 L87.2021816,35.0584141 C84.2024687,33.346259 81.2027557,31.6236639 78.2030428,29.9115088 C70.4985169,34.3276162 62.7834657,38.7437236 55.0684145,43.159831 C52.321309,41.5833955 49.5742034,40.00696 46.8270979,38.4305245 L31.9443115,29.8175491 L21.5032054,35.799652 L54.9947373,54.9778772 Z M105.874079,92.2068108 L105.874079,33.9413373 L85.61286,22.3320905 L85.61286,22.3425304 L78.2030428,18.1039026 L48.8900584,34.8913746 L55.0684145,38.4305245 L78.2030428,25.1822023 L86.5496125,29.9637087 L86.5601378,29.9637087 L96.7486365,35.799652 L54.9947373,59.7071837 L13.2513635,35.799652 L54.9947373,11.8921203 L66.5515262,18.5006214 L72.7404076,14.9614715 L54.9947373,4.80338056 L4.11539566,33.9413373 L4.11539566,92.2068108 L54.9947373,121.344768 L105.874079,92.2068108 Z M110,31.5714641 L110,94.5766841 C91.6649124,105.068854 73.3298249,115.571464 54.9947373,126.074074 C36.6596498,115.571464 18.3245622,105.068854 0,94.5766841 L0,31.5714641 C18.3245622,21.0792941 36.6596498,10.5766841 54.9947373,0.0740740741 C63.6675916,5.03305991 72.3299206,10.0024857 80.9922495,14.9614715 L80.9817242,14.9719115 L84.7392594,17.1225454 L84.7497847,17.1121054 C92.4753612,21.5386527 100.211463,25.9652001 107.93704,30.3917475 L110,31.5714641 Z"
							id="Fill-1"
						/>
						<path
							d="M61.0668016,103.22258 L93.3418522,85.0630899 C80.5293522,78.1105416 66.7520243,70.52135 61.0668016,67.0551813 L61.0668016,103.22258 Z M93.9331984,84.254654 L93.9331984,47.9255679 L61.4817814,66.1861127 C67.0632591,69.5310161 82.0647773,77.7972727 93.9331984,84.254654 L93.9331984,84.254654 Z M98,41.0740741 L98,87.0235468 L57,110.074074 L57,64.1246013 L98,41.0740741 Z"
							id="Fill-3"
						/>
						<path
							d="M48.5285931,66.1824355 L16.0668016,47.9346686 L16.0668016,84.2483309 C27.9455972,77.8019991 42.9471154,69.536953 48.5285931,66.1824355 L48.5285931,66.1824355 Z M48.9435729,103.21348 L48.9435729,67.0513768 C43.2583502,70.5271419 29.4810223,78.1051182 16.6685223,85.0667524 L48.9435729,103.21348 Z M53,64.1313299 L53,110.074074 L12,87.0168183 L12,41.0740741 L53,64.1313299 Z"
							id="Fill-5"
						/>
					</g>
				</g>
			</g>
		</svg>
	</div>
);

Logo.propTypes = {
	color: PropTypes.string,
	inverted: PropTypes.bool,
	className: PropTypes.string
};

Icon.propTypes = { className: PropTypes.string };

export const LogoAnimated = ({ className }) => (
	<svg width="111px" height="128px" viewBox="0 0 111 128" className={mergeClasses(styles.Animated, className)}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(0.000000, 1.000000)" stroke="#5B63B6">
				<path
					d="M55.4946,55.4038 L22.0036,36.2258 L32.4446,30.2438 C32.4446,30.2438 52.8216,42.0088 55.5686,43.5858 C63.2836,39.1698 70.9986,34.7538 78.7026,30.3378 C81.7026,32.0498 84.7026,33.7718 87.7026,35.4848 L88.9966,36.2258 L55.4946,55.4038 Z M55.4946,121.7708 L4.6156,92.6328 L4.6156,34.3668 L55.4946,5.2298 L73.2406,15.3868 L67.0516,18.9268 L55.4946,12.3178 L13.7516,36.2258 L55.4946,60.1328 L97.2486,36.2258 L87.0596,30.3898 L87.0496,30.3898 L78.7026,25.6078 L55.5686,38.8568 L49.3906,35.3178 L78.7026,18.5298 L86.1126,22.7688 L86.1126,22.7578 L106.3736,34.3668 L106.3736,92.6328 L55.4946,121.7708 Z M55.4946,0.4998 C37.1596,11.0028 18.8246,21.5058 0.4996,31.9978 L0.4996,95.0028 C18.8246,105.4948 37.1596,115.9978 55.4946,126.4998 C73.8296,115.9978 92.1646,105.4948 110.4996,95.0028 L110.4996,31.9978 L108.4366,30.8178 C100.7116,26.3908 92.9756,21.9648 85.2496,17.5378 L85.2396,17.5488 L81.4816,15.3978 L81.4926,15.3868 C72.8296,10.4278 64.1676,5.4588 55.4946,0.4998 L55.4946,0.4998 Z"
					className={styles.Animated_0}
				/>
				<path
					d="M61.5669,103.6485 L61.5669,67.4815 C67.2519,70.9475 81.0289,78.5365 93.8419,85.4885 L61.5669,103.6485 Z M94.4329,84.6805 C82.5649,78.2235 67.5639,69.9575 61.9819,66.6115 L94.4329,48.3515 L94.4329,84.6805 Z M98.4999,41.5005 L57.4999,64.5505 L57.4999,110.5005 L98.4999,87.4495 L98.4999,41.5005 Z"
					className={styles.Animated_1}
				/>
				<path
					d="M49.4434,103.6392 L17.1684,85.4932 C29.9814,78.5312 43.7584,70.9532 49.4434,67.4772 L49.4434,103.6392 Z M16.5674,84.6742 L16.5674,48.3602 L49.0284,66.6082 C43.4474,69.9632 28.4454,78.2282 16.5674,84.6742 L16.5674,84.6742 Z M12.5004,41.5002 L12.5004,87.4432 L53.5004,110.5002 L53.5004,64.5572 L12.5004,41.5002 Z"
					className={styles.Animated_2}
				/>
			</g>
		</g>
	</svg>
);

export default Logo;
