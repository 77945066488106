import { fromJS } from 'immutable';

import { CLEAR_MATERIAL, GET_MATERIAL } from './actions';

const initialState = fromJS({ material: null, currentMrp: '', currentSupplier: '' });

function materialReducer(state = initialState, action) {
	switch (action.type) {
		case GET_MATERIAL.SUCCESS:
			return state.set('material', fromJS(action.response));
		case GET_MATERIAL.FAILURE:
		case CLEAR_MATERIAL:
			return state.set('material', fromJS(null));
		default:
			return state;
	}
}

export default materialReducer;
