import { CALL_API, createRequestTypes } from 'modules/api';

export const GET_MATERIAL = createRequestTypes('GET_MATERIAL');
export const getMaterial = id => ({
	type: CALL_API,
	endpoint: `/material/${id}`,
	method: 'GET',
	callType: GET_MATERIAL
});

export const CLEAR_MATERIAL = 'App/material/CLEAR_MATERIAL';
export const clearMaterial = () => ({ type: CLEAR_MATERIAL });
