/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import DayJSUtils from '@date-io/dayjs';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from 'containers/Header';
import injectRootSagas from 'modules/sagas';
import InventoryVisualizationPage from 'pages/InventoryVisualizationPage/Loadable';
import LoginPage from 'pages/LoginPage/Loadable';
import MaterialPage from 'pages/MaterialPage/Loadable';
import NotFoundPage from 'pages/NotFoundPage/Loadable';
import OpportunitiesPage from 'pages/OpportunitiesPage/Loadable';
import OrdersPage from 'pages/OrdersPage/Loadable';
import RecoverPage from 'pages/RecoverPage/Loadable';
import ReportsPage from 'pages/ReportsPage/Loadable';
import ResetPage from 'pages/ResetPage/Loadable';
import SafetyStockPage from 'pages/SafetyStockPage/Loadable';
import SimulatorCalculatingPage from 'pages/SimulatorCalculatingPage/Loadable';
import SimulatorEditionPage from 'pages/SimulatorEditionPage/Loadable';
import SimulatorPage from 'pages/SimulatorPage/Loadable';
import SimulatorResultsPage from 'pages/SimulatorResultsPage/Loadable';
import SimulatorRoutingPage from 'pages/SimulatorRoutingPage/Loadable';
import ExperimentsPage from 'pages/ExperimentsPage/Loadable';
import theme from 'theme';
import Joyride from '../Joyride';
import styles from './styles.scss';

export const Fallback = <h1 style={{ padding: '20px' }}>Something went wrong.</h1>;

const SentryRoute = Sentry.withSentryRouting(Route);

function App() {
	injectRootSagas();

	return (
		<ThemeProvider theme={theme}>
			<div className={styles.App}>
				<CssBaseline />
				<MuiPickersUtilsProvider utils={DayJSUtils}>
					<Header />
					<Joyride />
					<main className={styles.Main}>
						<Sentry.ErrorBoundary fallback={Fallback}>
							<Switch>
								<Redirect exact from="/" to="/orders" />
								<SentryRoute exact path="/orders" component={OrdersPage} />
								<SentryRoute exact path="/reports" component={OpportunitiesPage} />
								<SentryRoute exact path="/material/:materialNumber" component={MaterialPage} />
								<SentryRoute exact path="/safetystock" component={SafetyStockPage} />
								<SentryRoute exact path="/inventory" component={InventoryVisualizationPage} />
								<SentryRoute exact path="/simulator" component={SimulatorPage} />
								<SentryRoute exact path="/co2reports" component={ReportsPage} />
								<SentryRoute exact path="/experiments" component={ExperimentsPage} />
								<SentryRoute exact path="/simulator/:comparisonId" component={SimulatorRoutingPage} />
								<SentryRoute path="/simulator/edit/:comparisonId" component={SimulatorEditionPage} />
								<SentryRoute path="/simulator/calculate/:comparisonId" component={SimulatorCalculatingPage} />
								<SentryRoute path="/simulator/results/:comparisonId" component={SimulatorResultsPage} />
								<SentryRoute exact path="/login" component={LoginPage} />
								<SentryRoute exact path="/reset" component={ResetPage} />
								<SentryRoute exact path="/recover" component={RecoverPage} />
								<SentryRoute path="" component={NotFoundPage} />
							</Switch>
						</Sentry.ErrorBoundary>
						<ToastContainer />
					</main>
				</MuiPickersUtilsProvider>
			</div>
		</ThemeProvider>
	);
}

export default App;
// export default Sentry.withProfiler(App);
