import { CALL_API, createRequestTypes } from 'modules/api';
import { getSearchParameter } from 'utils/url';

export const GET_OPPORTUNITIES = createRequestTypes('GET_OPPORTUNITIES');
export const getOpportunities = () => {
	let endpoint = `/company/${getSearchParameter('company') || 'me'}/opportunities`;
	if (window.cdashboard) {
		endpoint += `?view=${window.cdashboard}`;
	}
	return {
		type: CALL_API,
		endpoint,
		method: 'GET',
		callType: GET_OPPORTUNITIES
	};
};
