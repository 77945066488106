/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';

import { connectRouter } from 'connected-react-router';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import auth from 'modules/auth/reducer';
import calculate from './pages/SafetyStockPage/calculate/reducer';
import material from 'modules/material/reducer';
import routing from 'modules/routing/reducer';
import opportunitiesTable from 'pages/OpportunitiesPage/OpportunitiesTable/reducer';
import opportunities from 'pages/OpportunitiesPage/reducer';
import history from 'utils/history';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
	return combineReducers({
		language: languageProviderReducer,
		router: connectRouter(history),
		routing,
		form,
		opportunitiesTable,
		auth,
		calculate,
		material,
		opportunities,
		...injectedReducers
	});
}
